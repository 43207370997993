import React from 'react';
import styled from 'styled-components';
import type { ButtonProps, DivProps } from '../../models/widget/Styled';
import { default as SA } from '../Activity/Activity.styled';
import LanguageSelector from '../LanguageSelector';

export type StepperStyle = 'primary' | 'secondary';

interface BubbleTitleProps {
	$stepperStyle?: StepperStyle;
}

const InternalLanguageSelector = styled(LanguageSelector)`
	inset-inline-end: 10px;
`;

const InfoPanelOverlay = styled.div`
	&&& {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 9997;
		[data-texttype='markdown'] {
			margin-bottom: 0;
			font-weight: 400;
			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;
const InfoPanelBackground = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9998;
`;

const InfoPanel = styled.div`
	&&& {
		z-index: 9999;
		position: absolute;
		left: 50%;
		top: 10px;
		transform: translate(-50%, 0%);
		padding: 30px 20px;
		width: 400px;
		max-width: 85%;
		background-color: #dbe3f4;
		border-radius: 10px;
		text-align: center;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
		overflow-y: auto;
		max-height: 80vh;

		span {
			max-width: unset;
			text-align: unset;
			background-color: #dbe3f4;
			color: ${(props) => props.theme.colors.text};
		}

		[data-texttype='markdown'] {
			width: unset;
			max-width: unset;
			text-align: unset;
			background-color: #dbe3f4;
			color: ${(props) => props.theme.colors.text};
			margin-bottom: 0;
			font-weight: 400;

			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;

interface BubbleTitleWrapperProps {
	$widgetIsNarrow?: boolean;
	$modalOpen?: boolean;
}

const BubbleTitleWrapper = styled.div<BubbleTitleWrapperProps>`
	display: flex;
	padding-bottom: ${(props) => props.theme.spacings.medium};

	${(props) =>
		props.$modalOpen &&
		`
		flex-direction: column;	
  	`}

	// added for better (old) widget display at https://huisartsenpraktijkdebrink.uwartsonline.nl/
	// TODO: remove/change
	@media ${(props) => props.theme.screens.medium} {
		${(props) =>
			props.$widgetIsNarrow &&
			`
		flex-direction: column-reverse !important;
		gap: 32px !important;
		#language-selector-button {
			align-self: self-end !important;
		}
	  `}
	}
`;

const BubbleTitleStep = styled.div<BubbleTitleProps>`
	height: ${(props) => props.theme.spacings.large};
	aspect-ratio: 1;
	margin-right: ${(props) => props.theme.spacings.small};
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => (props.$stepperStyle === 'secondary' ? props.theme.colors.accent100 : props.theme.colors.accent)};
	color: ${(props) => (props.$stepperStyle === 'secondary' ? props.theme.colors.text900 : '#fff')};
	align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${(props) => props.theme.fontSizes.xs};
`;

const BubbleTitle = styled(SA.BubbleTitle)`
	flex-grow: 1;
	display: inline-flex;
	align-items: center;
	font-size: ${(props) => props.theme.fontSizes.base};

	&& {
		span {
			background: #fff;
			color: ${(props) => props.theme.colors.text900};
		}

		[data-texttype='markdown'] {
			background: #fff;
			color: ${(props) => props.theme.colors.text900};
		}
	}
`;

const BubbleTitleLower = styled(SA.BubbleTitle)`
	flex-grow: 1;
	display: inline-flex;
	align-items: center;

	&& {
		span {
			background: #fff;
			color: ${(props) => props.theme.colors.text900};
		}

		[data-texttype='markdown'] {
			background: #fff;
			color: ${(props) => props.theme.colors.text900};
		}
	}
`;

const BubbleTitleImage = styled(SA.MarkdownImage)`
	margin-top: ${(props) => props.theme.spacings.medium};
	margin-left: calc(-${(props) => props.theme.spacings.small} + -${(props) => props.theme.spacings.large});
	height: 268px;
	width: 268px;
`;

const ExtraInfo = styled.div`
	margin-top: ${(props) => props.theme.spacings.medium};
`;

const S = {
	infoPanelBackground: (props: DivProps) => (
		<InfoPanelBackground {...props} data-minddclass="infopanelbackground">
			{props.children}
		</InfoPanelBackground>
	),
	InfoPanelOverlay: (props: DivProps) => (
		<InfoPanelOverlay {...props} data-minddclass="infopaneloverlay">
			{props.children}
		</InfoPanelOverlay>
	),
	LanguageSelector: InternalLanguageSelector,
	InfoPanel: (props: DivProps) => (
		<InfoPanel {...props} data-minddclass="infopanel">
			{props.children}
		</InfoPanel>
	),
	BubbleTitle,
	BubbleTitleLower,
	BubbleTitleWrapper,
	BubbleTitleStep,
	BubbleTitleImage,
	ExtraInfo
};

export default S;
