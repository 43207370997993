import React, { useCallback, useMemo, useState } from 'react';
import { compiler } from 'markdown-to-jsx';

import S, { StepperStyle } from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import InfoButton from '../InfoButton/InfoButton';

interface Props {
	title?: string | JSX.Element;
	info?: string | JSX.Element;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	modalOpen?: boolean;
	disabled: boolean;
	limitHeight?: boolean;
	titleAriaHidden?: boolean;
	widgetIsNarrow?: boolean;
	stepNumber?: number;
	stepperStyle?: StepperStyle;
	style?: React.CSSProperties;
}

const ActivityBubbleTitle = (props: Props) => {
	const [{ settings, conversation }] = useStateContext();
	const [showInfo, setShowInfo] = useState(false);

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const BubbleTitle = props.limitHeight ? S.BubbleTitleLower : S.BubbleTitle;

	const title = useMemo(
		() =>
			typeof props.title === typeof 'foo'
				? compiler(props.title as string, {
						overrides: {
							p: {
								component: ({ children, ...props }) => <p {...props}>{children}</p>,
								props: {
									'data-texttype': 'markdown'
								}
							},
							img: {
								component: ({ children, ...props }) => <S.BubbleTitleImage {...props}>{children}</S.BubbleTitleImage>
							}
						}
				  })
				: props.title,
		[props.title]
	);

	return (
		<>
			<S.BubbleTitleWrapper $widgetIsNarrow={props.widgetIsNarrow} style={props.style} $modalOpen={props.modalOpen}>
				<BubbleTitle ref={focusLastActivity} tabIndex={-1} aria-hidden={props.titleAriaHidden} data-testid="bubble-title">
					<S.BubbleTitleStep $stepperStyle={props.stepperStyle} data-minddtag="question-title-step">
						{props.stepNumber ?? conversation.length}
					</S.BubbleTitleStep>
					{title}
					{props.info && !props.modalOpen && (
						<InfoButton>
							<>{typeof props.info === 'string' ? renderMarkdown(props.info) : props.info}</>
						</InfoButton>
					)}
				</BubbleTitle>
				{props.info && props.modalOpen && (
					<S.ExtraInfo>
						<>{typeof props.info === 'string' ? renderMarkdown(props.info) : props.info}</>
					</S.ExtraInfo>
				)}
			</S.BubbleTitleWrapper>
		</>
	);
};

export default ActivityBubbleTitle;
