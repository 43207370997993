import styled from 'styled-components';

interface CompactWidgetProps {
	$isSmall?: boolean;
}

const CompactWidget = styled.div<CompactWidgetProps>`
	padding-top: ${(props) => props.theme.spacings.medium};
	padding-left: ${(props) => props.theme.spacings.medium};
	padding-right: ${(props) => props.theme.spacings.medium};
	${(props) =>
		!props.$isSmall &&
		`
		padding: ${props.theme.spacings.smallMedium};
	`};
`;

interface WelcomeTextStartButtonWrapperProps {
	$isSmall?: boolean;
	$showWelcomeText?: boolean;
}

const WelcomeTextStartButtonWrapper = styled.div<WelcomeTextStartButtonWrapperProps>`
	display: flex;
	flex-direction: column;
	${(props) =>
		!props.$isSmall &&
		`
		flex-direction: row;
	`};
`;

const StartButtonWrapper = styled.div`
	flex: 1;
`;

interface WelcomeTextWrapperProps {
	$isSmall?: boolean;
}

const WelcomeTextWrapper = styled.div<WelcomeTextWrapperProps>`
	margin-top: ${(props) => props.theme.spacings.medium};
	${(props) =>
		!props.$isSmall &&
		`
		margin-top: 0;
		flex: 2;
		display: flex;
		align-items: center;
	`};
`;

interface CompactWidgetFooterProps {
	$isSmall?: boolean;
}

const CompactWidgetFooter = styled.div<CompactWidgetFooterProps>`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${(props) => props.theme.colors.borderLight};

	${(props) =>
		!props.$isSmall &&
		`
		flex-direction: row;
		justify-content: space-between;
		padding-top: ${props.theme.spacings.medium};
		padding-bottom: ${props.theme.spacings.medium};
	`};
`;

export default {
	CompactWidget,
	WelcomeTextStartButtonWrapper,
	StartButtonWrapper,
	WelcomeTextWrapper,
	CompactWidgetFooter
};
